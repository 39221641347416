import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import React, { Component } from 'react';

class ModalBridge extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.modalRef = React.createRef();
    }

    componentDidMount = () => {
        if (window.deBridge) {
            window.deBridge.widget({
              v: "1",
              element: "debridgeWidget", // Ensure this div exists
              title: "",
              description: "",
              width: `${window.innerWidth * 0.4}px`, // 40vw
              height: `${window.innerHeight * 0.8}px`, // 80vh
              r: null,
              supportedChains: {
                inputChains: {
                  1: "all",
                  10: "all",
                  56: "all",
                  100: "all",
                  137: "all",
                  146: "all",
                  250: "all",
                  388: "all",
                  747: "all",
                  998: "all",
                  1088: "all",
                  1514: "all",
                  2741: "all",
                  4158: "all",
                  7171: "all",
                  8453: "all",
                  32769: "all",
                  42161: "all",
                  43114: "all",
                  48900: "all",
                  59144: "all",
                  80094: "all",
                  7565164: "all",
                  245022934: "all",
                },
                outputChains: {
                  1: "all",
                  10: "all",
                  56: "all",
                  100: "all",
                  137: "all",
                  146: "all",
                  250: "all",
                  388: "all",
                  747: "all",
                  998: "all",
                  999: "all",
                  1088: "all",
                  1514: "all",
                  2741: "all",
                  4158: "all",
                  7171: "all",
                  8453: "all",
                  32769: "all",
                  42161: "all",
                  43114: "all",
                  48900: "all",
                  59144: "all",
                  80094: "all",
                  7565164: "all",
                  245022934: "all",
                },
              },
              inputChain: 56,
              outputChain: 1,
              inputCurrency: "",
              outputCurrency: "",
              address: "",
              showSwapTransfer: true,
              amount: "",
              outputAmount: "",
              isAmountFromNotModifiable: false,
              isAmountToNotModifiable: false,
              lang: "en",
              mode: "deswap",
              isEnableCalldata: false,
              styles: "e30=",
              theme: "dark",
              isHideLogo: false,
              logo: "",
              disabledWallets: [],
              disabledElements: [],
            });
        }

        window.addEventListener('mousedown', this.pageClick, false);
        this.disableScroll();
    }

    componentWillUnmount = () => {
        clearAllBodyScrollLocks();
    }

    disableScroll = () => {
        let body = document.querySelector('#targetElementId');
        disableBodyScroll(body);
    }

    pageClick = (e) => {
        if (this.modalRef && this.modalRef.current && !this.modalRef.current.contains(e.target)) {
            this.closeModal()
        }
    }

    closeModal = () => {
        this.props.closeModal && this.props.closeModal()
    }

    render() {
        return (
            <div className='modal-overlay'>
                <div className='modal-container modal-bridge' ref={this.modalRef} style={{padding: 0, height: 'fit-content', width: 'fit-content', borderRadius: '5px'}} >
                    <div id="debridgeWidget"></div>
                </div>
            </div>
        )
    }
}

export default ModalBridge;